<template>
  <v-container class="without-item-card">
    <div class="df-flex-l df-flex-col justify-center align-center card-content">
      <div v-if="shownIcon" class="content__icon">
        <font-awesome-icon
          data-id="without-item-icon"
          color="#5B6459"
          :icon="shownIcon"
        />
      </div>
      <div class="df-flex-sm df-flex-col card__heading">
        <h1 class="heading__title">{{ $t(title) }}</h1>
        <p class="heading__text" v-html="$t(text)"></p>
      </div>
      <df-button
        v-if="showOpenFieldsButton"
        data-id="field-button"
        @click="openFieldsModal"
      >
        {{ $t('WithoutItemCard.field.create_field') }}
      </df-button>
      <df-button v-if="showAddSowingButton" @click="openAddSowingModal">
        {{ $t('WithoutItemCard.sowing.add_sowing') }}
      </df-button>
      <df-button v-if="showExecuteSowingButton" @click="openExecuteSowingModal">
        {{ $t('WithoutItemCard.sowing.execute_sowing') }}
      </df-button>
      <div v-if="showSprayingButtons" class="df-flex-sm">
        <df-button
          class="plan__spraying"
          depressed
          variant="text"
          @click="redirectToPlanning"
        >
          <p>{{ $t('WithoutItemCard.spraying.plan') }}</p>
        </df-button>
        <df-button @click="openAddSprayingModal">
          {{ $t('WithoutItemCard.spraying.add_spraying') }}
        </df-button>
      </div>
      <df-button v-if="showAddProgramButton" @click="openAddProgramModal">
        {{ $t('WithoutItemCard.program.add_program') }}
      </df-button>
    </div>
    <add-sowing-modal
      v-if="isAddSowingModalOpened"
      @close="closeAddSowingModal"
    />
    <add-spraying-modal
      v-if="isAddSprayingModalOpened"
      :crop-name="cropName"
      :sowing-id="Number(this.$route.query.sowing_id)"
      @close="closeAddSprayingModal"
    />
    <execute-sowing-modal
      v-if="isExecuteSowingModalOpened"
      @close="closeExecuteSowingModal"
    />
    <modal-talhoes
      v-if="isFieldsModalOpened"
      :farm="currentFarm"
      @close-modal="closeFieldsModal"
    />
    <add-program-modal
      v-if="isAddProgramModalOpened"
      @close="closeAddProgramModal"
    />
  </v-container>
</template>

<script>
const AddProgramModal = () =>
  import('@/modules/diseasesControl/components/modal/AddProgramModal.vue')
const AddSowingModal = () =>
  import('@/modules/sowingsPlanning/components/modal/AddSowingModal.vue')
const AddSprayingModal = () =>
  import('@/modules/sowingView/components/modal/AddSprayingModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
const ExecuteSowingModal = () =>
  import('@/modules/sowingsList/components/modal/ExecuteSowingModal.vue')
const ModalTalhoes = () => import('@/pages/fazendas/ModalTalhoes.vue')
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'WithoutItemCard',

  components: {
    AddProgramModal,
    AddSowingModal,
    AddSprayingModal,
    DfButton,
    ExecuteSowingModal,
    ModalTalhoes,
  },

  props: {
    icon: {
      default: '',
      type: String,
    },
    name: {
      default: 'field',
      type: String,
      validator: (value) =>
        [
          'add-field',
          'add-program',
          'add-spraying',
          'add-sowing',
          'execute-sowing',
          'field-sowing',
          'filter',
          'sowing',
        ].includes(value),
    },
    text: {
      type: String,
      default: 'Home.without_field.text',
    },
    title: {
      type: String,
      default: 'Home.without_field.title',
    },
  },

  data() {
    return {
      isAddProgramModalOpened: false,
      isAddSowingModalOpened: false,
      isAddSprayingModalOpened: false,
      isExecuteSowingModalOpened: false,
      isFieldsModalOpened: false,
    }
  },

  inject: {
    getCropName: {
      default: () => '',
      from: 'getCropName',
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarm']),
    cropName() {
      return this.getCropName().split('.')[1]
    },
    showAddProgramButton() {
      return this.name === 'add-program'
    },
    showExecuteSowingButton() {
      return this.name === 'execute-sowing'
    },
    showSprayingButtons() {
      return this.name === 'add-spraying'
    },
    shownIcon() {
      if (this.icon) return this.icon
      const iconsObj = {
        'add-field': 'vector-square',
        'add-program': 'list',
        'add-spraying': 'tractor',
        'add-sowing': 'seedling',
        'execute-sowing': 'seedling',
        'field-sowing': 'seedling',
        filter: 'search',
        sowing: 'seedling',
      }
      return iconsObj[this.name]
    },
    showOpenFieldsButton() {
      return this.name === 'add-field'
    },
    showAddSowingButton() {
      return this.name === 'add-sowing'
    },
  },

  methods: {
    closeAddProgramModal() {
      this.isAddProgramModalOpened = false
    },
    closeAddSowingModal() {
      this.isAddSowingModalOpened = false
    },
    closeAddSprayingModal() {
      this.isAddSprayingModalOpened = false
    },
    closeExecuteSowingModal() {
      this.isExecuteSowingModalOpened = false
    },
    closeFieldsModal() {
      this.isFieldsModalOpened = false
    },
    openAddProgramModal() {
      logEvent(events.diseasesControlModule.DiseasesControl.new_program)
      this.isAddProgramModalOpened = true
    },
    openAddSprayingModal() {
      logEvent(events.sowingViewModule.SowingView.create_spraying)
      this.isAddSprayingModalOpened = true
    },
    openAddSowingModal() {
      logEvent(events.sowingsPlanningModule.SowingsPlanning.add_sowing)
      this.isAddSowingModalOpened = true
    },
    openExecuteSowingModal() {
      logEvent(events.sowingsListModule.SowingsList.add_sowing_in_season)
      this.isExecuteSowingModalOpened = true
    },
    openFieldsModal() {
      logEvent(events.homeModule.clickedButtonCreateField)
      this.isFieldsModalOpened = true
    },
    redirectToPlanning() {
      this.$router.push({
        path: 'sowings_planning',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.without-item-card {
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px 16px !important;

  .card-content {
    max-width: 560px;
    margin: 0px auto;

    .content__icon {
      border-radius: 999999px;
      background-color: #f8f9f8;
      height: 52px;
      width: 52px;
      position: relative;

      & svg {
        position: absolute;
        top: 18px;
        left: 18px;
      }
    }
    .card__heading {
      .heading__title {
        color: #1a2b46;
        text-align: center;
        font-family: 'Rubik';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        &:first-letter {
          text-transform: capitalize;
        }
      }
      .heading__text {
        color: #5b6459;
        text-align: center;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
    .plan__spraying {
      p {
        color: #39af49 !important;
      }
    }
  }
}
::v-deep .v-btn__content p:first-letter {
  text-transform: capitalize;
}
</style>
